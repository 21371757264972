<template>
  <div>
    <v-form ref="form">
      <div class="row mt-3">
        <input-text
          v-model="form.codigo"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_calibracao.formulario.codigo')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.codigo_placeholder')
          "
          :error="error.codigo.status"
          :error-messages="error.codigo.message"
          :max="20"
          trim
          obrigatorio
        />
        <input-text
          v-model="form.nome"
          class="col-12 col-md-3"
          :label="$t('modulos.servico_calibracao.formulario.nome')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.nome_placeholder')
          "
          :max="150"
          trim
          obrigatorio
        />
        <input-escopo-servico
          ref="inputEscopo"
          v-model="form.escopo"
          class="col-12 col-md-3"
          :label="$t('modulos.servico_calibracao.formulario.escopo_servico')"
          :multiplos="false"
          obrigatorio
          prevent-acoes
          @prevent-abrir-modal="abrirModalEscopo"
        />
        <input-select
          v-model="form.tipoCalibracaoId"
          :options="opcoes.tipoCalibracao"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_calibracao.formulario.tipo_calibracao')"
          obrigatorio
          :disabled="bloquearCamposEscopo"
        />
        <input-select
          v-model="form.modoExibicaoDescricaoContrato"
          :options="opcoes.tipoExebicao"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_calibracao.formulario.tipo_exibicao')"
          obrigatorio
        />
        <input-select
          v-model="form.localExecucaoId"
          :label="$t('modulos.servico_manutencao.formulario.local_execucao')"
          class="col-12 col-md-2"
          :options="opcoes.localExecucao"
          retornar-objeto
          obrigatorio
          :disabled="bloquearCamposEscopo"
        />
        <input-laboratorio
          v-model="form.participanteLaboratorioId"
          :label="$t('modulos.servico_calibracao.formulario.laboratorio')"
          class="col-12 col-md-2"
          :multiplos="false"
          :flag-fornecedor-laboratorio="true"
        />
        <input-text
          v-model="form.minimoPontos"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_calibracao.formulario.minimo_pontos')"
          :mascara="mascaraDocumento(form.documento)"
          type="number"
          obrigatorio
        />
        <input-unidade-medida
          ref="inputUnidadeMedida"
          v-model="form.unidadeMedidaId"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.unidade_medida')"
          :multiplos="false"
          obrigatorio
          :error="error.unidade_medida.status"
          :error-messages="error.unidade_medida.message"
          :disabled="bloquearCamposEscopo"
          :listar-unidade-medida-por-grandezas-fisicas-ids="true"
          :grandezas-fisicas-ids="grandezasFisicasIds"
          prevent-acoes
          @prevent-abrir-modal="abrirModalUnidadeMedida"
        />
        <v-form
          ref="amplitudeForm"
          class="col-12 col-md-4 d-flex"
        >
          <input-text
            v-model="form.amplitudeMinima"
            class="flex-fill"
            :label="$t('modulos.servico_manutencao.formulario.amplitude')"
            :placeholder="
              $t('modulos.servico_manutencao.formulario.amplitude_min')
            "
            obrigatorio
            :disabled="desabilitado"
            type="number"
            @input="validarAmplitude"
          />
          <!-- :regras-personalizadas="regraAmplitude()" -->
          <div class="mx-2 mt-12">
            a
          </div>
          <input-text
            v-model="form.amplitudeMaxima"
            class="flex-fill mt-6"
            :placeholder="
              $t('modulos.servico_manutencao.formulario.amplitude_max')
            "
            obrigatorio
            :disabled="desabilitado"
            type="number"
            @input="validarAmplitude"
          />
          <!-- :regras-personalizadas="regraAmplitude()" -->
        </v-form>
        <input-money
          v-model="form.valorServico"
          class="col-12 col-md-2 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.valor_servico')"
          :placeholder="
            $t(
              'modulos.servico_calibracao.formulario.valor_servico_placeholder'
            )
          "
          :max="19"
          obrigatorio
        />
        <input-money
          v-model="form.valorPonto"
          class="col-12 col-md-2 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.valor_ponto')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.valor_ponto_placeholder')
          "
          :max="19"
          obrigatorio
        />
        <input-money
          v-model="form.valorHora"
          class="col-12 col-md-2 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.valor_hora')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.valor_hora_placeholder')
          "
          :max="19"
          obrigatorio
        />
        <input-money
          v-model="form.tempoExecucao"
          class="col-12 col-md-3 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.tempo_execucao')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.tempo_execucao')
          "
          :options="optionsTempoDesconto"
        />
        <input-money
          v-model="form.desconto"
          class="col-12 col-md-3 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.desconto')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.desconto_placeholder')
          "
          :options="optionsTempoDesconto"
        />
        <input-textarea
          v-model="form.descricao"
          class="col-12 col-md-12 pt-0"
          :label="$t('modulos.servico_calibracao.formulario.descricao')"
          :max="1000"
          trim
          obrigatorio
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import {
  InputLaboratorio,
  InputUnidadeMedida,
  InputMoney,
} from '@components/inputs';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import LocalExecucaoService from '@common/services/cadastros/LocalExecucaoService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputEscopoServico } from '@components/inputs';
export default {
  components: {
    InputLaboratorio,
    InputUnidadeMedida,
    InputMoney,
    InputEscopoServico,
  },
  props: {
    form: {},
    erros: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      amplitudeMinimaPermida: null,
      amplitudeMaximaPermitida: null,
      optionsTempoDesconto: {
        locale: 'pt-BR',
        prefix: null,
        suffix: '',
        precision: 2,
      },
      mensagem: null,
      desabilitado: true,
      opcoes: {
        tipoCalibracao: [],
        tipoExebicao: [],
        localExecucao: [],
      },
      error: {
        codigo: {
          status: false,
          message: '',
        },
        amplitude_maxima: {
          status: false,
          message: '',
        },
        unidade_medida: {
          status: false,
          message: '',
        },
      },
      grandezasFisicasIds: [],
    };
  },
  computed: {
    unidadeAmplitudeEhObrigatorio: function () {
      if (this.form.unidadeMedidaId) return true;
      if (this.form.amplitudeMaxima) return true;
      if (this.form.amplitudeMinima) return true;
      return false;
    },
    bloquearCamposEscopo: function () {
      if (!this.form.escopo) return true;
      return false;
    },
  },
  watch: {
    erros: {
      deep: true,
      handler(value) {
        if (value.includes('codigoJaUtilizado')) {
          this.error.codigo = {
            status: true,
            message: this.$t(
              'modulos.servico_calibracao.formulario.erros.codigo_existente'
            ),
          };
        }
        if (value.includes('amplitudeMaximaDeveSerMaiorQueAmplitudeMinima')) {
          this.error.amplitude_maxima = {
            status: true,
            message: this.$t(
              'modulos.servico_calibracao.formulario.erros.amplitude_maxima'
            ),
          };
        }
        if (value.includes('preencherUnidadeMedida')) {
          this.error.unidade_medida = {
            status: true,
            message: this.$t(
              'modulos.servico_calibracao.formulario.erros.unidade_medida'
            ),
          };
        }
      },
    },
    form: {
      deep: true,
      handler() {
        if (this.erros.length) {
          this.resetErrors();
          this.$emit('clear-errors');
        }
      },
    },
    'form.unidadeMedidaId': {
      deep: true,
      handler() {
        if (this.form.unidadeMedidaId) {
          this.desabilitado = false;
        }
      },
    },
  },
  mounted() {
    this.listarTipoCalibracao();
    this.listarTipoExibicao();
    this.listarLocalExecucao();
  },
  methods: {
    regraAmplitude: function () {
      if (!this.form.amplitudeMaxima || !this.form.amplitudeMinima) return [];

      let regraValor = (v) => {
        let valor = `${v}`;
        let intValue = valor.split('.')[0];
        let decimalValue = valor.split('.')[1];
        if (intValue.length > 10) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            char: 10,
            decimal: 9,
          });
        }

        if (decimalValue && decimalValue.length > 9) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            decimal: 9,
            char: 10,
          });
        }
        return true;
      };

      let regraValorMaior = () =>
        parseFloat(this.form.amplitudeMaxima) >=
        parseFloat(this.form.amplitudeMinima)
          ? true
          : this.$t(
              'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
            );

      let regraValorNoEscopo = () => {
        if (this.form.amplitudeMaxima > this.amplitudeMaximaPermitida) {
          return this.$t(
            'modulos.servico_calibracao.erros.amplitudeMaximaNaoPodeSerMaiorQueAmplitudeMaximaPermitida'
          );
        }
        if (this.form.amplitudeMinima < this.amplitudeMinimaPermida) {
          return this.$t(
            'modulos.servico_calibracao.erros.amplitudeMinimaNaoPodeSerMenorQueAmplitudeMinimaPermitida'
          );
        }
        return true;
      };

      return [regraValor, regraValorMaior, regraValorNoEscopo];
    },
    listarTipoCalibracao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoCalibracaoService.listar({})
        .then((res) => {
          this.opcoes.tipoCalibracao = new DropdownModel(res.data);
        })
        .catch(() => {
          this.opcoes.tipoCalibracao = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarTipoExibicao: function () {
      EnumeradorService.buscar('EnumModoExibicaoDescricaoContrato').then(
        (res) => {
          this.opcoes.tipoExebicao = res;
        }
      );
    },
    resetErrors() {
      this.error.codigo.status = false;
      this.error.codigo.message = '';
      this.error.amplitude_maxima.status = false;
      this.error.amplitude_maxima.message = '';
      this.error.unidade_medida.status = false;
      this.error.unidade_medida.message = '';
    },
    listarLocalExecucao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalExecucaoService.listar({ amountPerPage: 1000, page: 1 })
        .then((res) => {
          this.opcoes.localExecucao = new DropdownModel(res.data);
        })
        .catch(() => {
          this.opcoes.localExecucao = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    verificacaoDeAmplitudeNoEscopoSelecionarTipoCalibracao: function () {
      const amplitudes = [];
      this.grandezasFisicasIds = [];
      if (this.form.escopo && this.form.escopo.faixas) {
        this.form.escopo.faixas.forEach((faixa) => {
          amplitudes.push(faixa.amplitudeMinima);
          amplitudes.push(faixa.amplitudeMaxima);
          if (!this.grandezasFisicasIds.includes(faixa.grandezaFisicaId.id)) {
            this.grandezasFisicasIds.push(faixa.grandezaFisicaId.id);
          }
        });
        this.amplitudeMaximaPermitida = Math.max(...amplitudes);
        this.amplitudeMinimaPermida = Math.min(...amplitudes);
      }
      this.opcoes.tipoCalibracao = new DropdownModel(
        this.form.escopo?.tiposCalibracoes
      );
      this.opcoes.localExecucao = new DropdownModel(
        this.form.escopo.locaisExecucoes
      );
      if (this.form.escopo.tiposCalibracoes?.length == 1) {
        this.form.tipoCalibracaoId = this.form.escopo.tiposCalibracoes[0].id;
      }
      if (this.form.escopo.locaisExecucoes) {
        this.form.localExecucaoId = new DropdownModel(
          this.form.escopo.locaisExecucoes[0]
        );
      }
    },
    definirGrandezasFisicasEscopoServicoJaSanadoPelaKeL(){
      if (this.form.escopo && this.form.escopo.faixas) {
        this.form.escopo.faixas.forEach((faixa) => {
          const grandezaId = faixa.grandezaFisicaId?.id ?? faixa.grandezaFisica?.id;
          if (!this.grandezasFisicasIds.includes(grandezaId)) {
            this.grandezasFisicasIds.push(grandezaId);
          }
        });
      }
    },
    validarAmplitude() {
      if (!this.form.amplitudeMinima && !this.form.amplitudeMaxima) return;
      this.$refs.amplitudeForm.validate();
    },
    abrirModalEscopo: function () {
      if (!this.form.escopo) {
        this.$refs.inputEscopo.abrirModal();
        return;
      }

      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(
          'modulos.servico_calibracao.formulario.confirmar_alteracao_dados'
        )
      ).then(() => {
        this.$refs.inputEscopo.abrirModal();
      });
    },
    abrirModalUnidadeMedida: function () {
      // if (!this.form.unidadeMedidaId) {
        this.$refs.inputUnidadeMedida.abrirModal();
      //   return;
      // }

      // this.confirmar(
      //   this.$t('geral.titulos.atencao'),
      //   this.$t(
      //     'modulos.servico_calibracao.formulario.confirmar_alteracao_dados_unidade_medida'
      //   )
      // ).then(() => {
      //   this.$refs.inputUnidadeMedida.abrirModal();
      // });
    },
  },
};
</script>
