import _ from 'lodash';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { DropdownEscopoServico } from '@common/models/geral/DropdownEscopoServico';

export class ServicoCalibracaoModel {
  constructor({
    id,
    codigo,
    nome,
    descricao,
    tipoServico = 1,
    tipoCalibracaoId,
    localExecucaoId,
    participanteLaboratorioId,
    amplitudeMinima,
    amplitudeMaxima,
    unidadeMedidaId,
    minimoPontos,
    tempoExecucao,
    valorServico,
    valorPonto,
    valorHora,
    desconto,
    servicoInstrumentos = [],
    modoExibicaoDescricaoContrato,
    unidadeMedida,
    localExecucao,
    participanteLaboratorio,
    escopo,
  }) {
    this.id = id;
    this.codigo = codigo;
    this.nome = nome;
    this.descricao = descricao;
    this.tipoServico = tipoServico;
    this.tipoCalibracaoId = tipoCalibracaoId;
    this.localExecucaoId = localExecucaoId;
    if (localExecucao) this.localExecucaoId = new DropdownModel(localExecucao);
    this.participanteLaboratorioId = participanteLaboratorioId;
    if (participanteLaboratorio)
      this.participanteLaboratorioId = new DropdownModel(
        participanteLaboratorio
      );
    this.amplitudeMinima = amplitudeMinima;
    this.amplitudeMaxima = amplitudeMaxima;
    this.unidadeMedidaId = unidadeMedidaId;
    if (unidadeMedida) this.unidadeMedidaId = new DropdownModel(unidadeMedida);
    this.minimoPontos = minimoPontos;
    this.tempoExecucao = tempoExecucao;
    this.valorServico = valorServico;
    this.valorPonto = valorPonto;
    this.valorHora = valorHora;
    this.desconto = desconto;
    this.servicoInstrumentos = servicoInstrumentos;
    this.modoExibicaoDescricaoContrato = modoExibicaoDescricaoContrato;
    this.escopo = escopo;
    if (escopo) this.escopo = new DropdownEscopoServico(escopo)
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.unidadeMedidaId = retorno.unidadeMedidaId?.value;
    retorno.localExecucaoId = retorno.localExecucaoId?.value;
    retorno.participanteLaboratorioId =
      retorno.participanteLaboratorioId?.value;
    retorno.servicoInstrumentos = this.servicoInstrumentos.map((item) => {
      return {
        tipoInstrumentoId: item.tipoInstrumento.id,
        id: item.id,
        modeloInstrumentoId: item.modeloInstrumento?.id,
      };
    });
    retorno.escopoId = retorno.escopo?.value;
    return retorno;
  }
}
